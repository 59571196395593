import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';

import Img from 'gatsby-image';
import Moment from 'react-moment';
import { Fade } from 'react-slideshow-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import GalleryCard from '../components/galleryCard';
import ProductCard from '../components/productCard';
import ProcessSequence from '../components/processSequence';
import SubscriptionBar from '../components/SubscriptionBar';

import '../utils/normalize.css';
import '../utils/css/screen.css';
import 'react-slideshow-image/dist/styles.css';

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        phoneNumber
      }
    }
    artJEater: file(
      relativePath: { eq: "art-j-eater.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    examples: allMarkdownRemark(
      sort: { fields: [frontmatter___order,frontmatter___date,], order: ASC },
      filter: { 
        fileAbsolutePath: {regex : "\/examples/"} ,
        frontmatter: { ignore: { ne: true } }
      },
      limit: 15,
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            anim {
              id
              publicURL
              name
            }
            details
            source {
              childImageSharp {
                fluid(maxWidth: 480) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    animationGrids: allMarkdownRemark(
      sort: { fields: [frontmatter___order, frontmatter___title], order: [ASC, DESC] },
      filter: {
          fileAbsolutePath: {regex : "\/grids/"},
          frontmatter: { locked: { eq: false }, title: { in: ["Three frame loop", "Six frame loop", "Nine frames with FG/BG"] } }
        },
        limit: 6,
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            difficulty
            description
            locked
            printFile {
              id
              publicURL
              name
            }
            thumb {
              childImageSharp {
                fluid(maxWidth: 280) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            finished {
              childImageSharp {
                fluid(maxWidth: 280) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    effects: allMarkdownRemark(
      sort: { fields: [frontmatter___order,frontmatter___date,], order: ASC },
      filter: { 
        fileAbsolutePath: {regex : "\/effects/"} ,
        frontmatter: { ignore: { ne: true } }
      },
      limit: 99,
    ) {
      edges {
        node {
          fields {
            slug
          }
          html
          frontmatter {
            title
            shorthand
            anim {
              id
              publicURL
              name
            }
          }
        }
      }
    }
    books: allMarkdownRemark(
      sort: { fields: [frontmatter___order, frontmatter___title], order: [ASC, DESC] },
      filter: {
          fileAbsolutePath: {regex : "\/books/"},
          frontmatter: { title: { in: ["Activity Book - Extremely Silly Faces", "Activity Book - Naughty Cats & Kittens", "Activity Book - Textable Reaction GIFs"] } }
        },
        limit: 3,
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            purchaseLink
            purchaseLabel
            quickDescription
            details
            thumb {
              childImageSharp {
                fluid(maxWidth: 360) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

const SiteIndex = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title;
  const {
    animationGrids, examples, effects, processExample, books
  } = data;
  let exampleCounter = 0;
  let animGridCounter = 0;
  let bookCounter = 0;

  console.log('asdf', books);

  const shuffledExamples = examples.edges;

  // Filter for examples with input source images available
  const processExamples = shuffledExamples.filter(obj => (obj.node.frontmatter.source));

  const url = location.href ? location.href : '';

  return (
    <Layout title={siteTitle}>
      <SEO
        title="Animation. For everyone."
        keywords={['animation', 'sketch', 'sketchbooks', 'gifs', 'paper', 'hand drawn']}
      />
      {data.site.siteMetadata.description && (
        <header className="page-head">
          <h2 className="page-head-title">
            {data.site.siteMetadata.description}
          </h2>
        </header>
      )}

      <div className="gallery-feed">
        {shuffledExamples.map(({ node }) => {
          exampleCounter += 1;
          return (
            <GalleryCard
              key={node.fields.slug}
              count={exampleCounter}
              node={node}
              postClass="post"
            />
          );
        })}
      </div>

      <article className="post-content page-template no-image home-container">
        <div className="post-content-body">
          <p className="home-page-excerpt short">
            Create hand-drawn GIFs on&nbsp;paper.
          </p>
          <div className="home-slide-container">
            <Fade
              arrows={false}
              pauseOnHover={false}
              duration={4700}
              transitionDuration={400}
            >
              { processExamples.map(({ node }) => (
                <div className="each-fade">
                  <Link to={node.fields.slug}>
                    <ProcessSequence
                      key={node.fields.slug}
                      inputSrc={node.frontmatter.source}
                      outputSrc={node.frontmatter.anim.publicURL}
                    />
                  </Link>
                </div>
              ))}
            </Fade>
          </div>
          <hr />
          <h2 className="page-head-title">
            How it works
          </h2>
          <p className="home-page-excerpt">
            Ready to make a GIF? It&apos;s easy!
          </p>
          <dl>
            <dt>
              1. Print
              {' '}
              <span role="img" aria-label="printer">🖨️</span>
            </dt>
            <dd>
              <p>
                Print a template.
              </p>
            </dd>
            <dt>
              2. Draw
              {' '}
              <span role="img" aria-label="pencil">✏️</span>
            </dt>
            <dd>
              <p>
                Draw in each frame.
              </p>
            </dd>
            <dt>
              3. Upload
              {' '}
              <span role="img" aria-label="phone">📱</span>
            </dt>
            <dd>
              <p>
                Take a photo and
                {' '}
                <Link to="/uploader"><strong>upload.</strong></Link>
              </p>
            </dd>
            Arteater digests your art and
            {' '}
            <strong>
            transforms it into an animated GIF
            </strong>
            {' '}
            you can save and share.
          </dl>
          <hr />
          <Link to="/templates" style={{ textDecoration: 'none' }}>
            <h2 className="page-head-title">
              Templates
            </h2>
          </Link>
          <p className="home-page-excerpt">
            Print these.
          </p>
          <div className="grid-previews">
            <div className="row">
              {animationGrids.edges.map(({ node }) => {
                const grid = node.frontmatter;
                animGridCounter += 1;
                return (
                  <div
                    className="col-3"
                    key={animGridCounter}
                  >
                    <ProductCard {...grid} link={`/templates${node.fields.slug}`} />
                  </div>
                );
              })}
              <div
                className="col-3"
                key="link-templates"
              >
                <h6 style={{ textAlign: 'right', backgroundColor: 'rgba(255,255,255,0.98)' }}>
                  <Link to="/templates">
                    <strong>
                      More templates 📖 >
                    </strong>
                  </Link>
                </h6>
              </div>
            </div>
          </div>
          <hr />
          <br />
          <h2 className="page-head-title">
            Effects
          </h2>
          <p className="home-page-excerpt">
            Apply generous seasoning.
          </p>
          <table>
            <thead>
              <tr>
                <th>Output</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {effects.edges.map(({ node }) => (
                <tr key={node.fields.slug}>
                  <td className="effect-example">
                    <img
                      src={node.frontmatter.anim.publicURL}
                      alt="effect example"
                    />
                  </td>
                  <td>
                    <strong>
                      {node.frontmatter.title}
                    </strong>
                    { (node.frontmatter.shorthand && node.frontmatter.shorthand !== '-') && (
                      <>
                        {' ( '}
                        {node.frontmatter.shorthand}
                        {')'}
                      </>
                    )}
                    <br />
                    <span dangerouslySetInnerHTML={{ __html: node.html }} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <p>
            Enter effects on the 
            {' '}
            <Link to="/uploader"><strong>Uploader</strong></Link>
            {' '}
            page.
          </p>
          <hr />
          <br />
          <Link to="/templates" style={{ textDecoration: 'none' }}>
            <h2 className="page-head-title">
              Books
            </h2>
          </Link>
          <p className="home-page-excerpt">
            Full of hands-on GIF activities.
          </p>
          <div className="grid-previews">
            <div className="row">
              {books.edges.map(({ node }) => {
                const book = node.frontmatter;
                bookCounter += 1;
                return (
                  <div
                    className="col-3"
                    key={bookCounter}
                  >
                    <ProductCard 
                      title={book.title} 
                      thumb={book.thumb}
                      priceLabel={'More info'} 
                      priceUrl={`/books${node.fields.slug}`} 
                      link={`/books${node.fields.slug}`}
                      description="40 pages of GIFs" 
                    />
                  </div>
                );
              })}
              <div
                className="col-3"
                key="link-templates"
              >
                <h6 style={{ textAlign: 'right', backgroundColor: 'rgba(255,255,255,0.98)' }}>
                  <Link to="/books">
                    <strong>
                      More books 🛒 >
                    </strong>
                  </Link>
                </h6>
              </div>
            </div>
          </div>
          <hr />
          <SubscriptionBar
            prompt="Subscribe for email updates on Arteater."
            subscriptionId="home-page-subscribe"
            referenceUrl={url}
          />
        </div>
      </article>
      <Link to="/art-j-eater">
        <Img
          fluid={data.artJEater.childImageSharp.fluid}
          className="footer-art-j"
        />
      </Link>
    </Layout>
  );
};

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <SiteIndex location={props.location} props data={data} {...props} />
    )}
  />
);
