import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import ImageStack from './imageStack';

import '../utils/normalize.css';
import '../utils/css/screen.css';

const ProductCard = (props) => {
  const {
    title,
    thumb,
    finished,
    printFile,
    difficulty,
    description,
    priceLabel,
    priceUrl,
    locked,
    unlockCode,
    link,
  } = props;

  const [codeUnlocked, setCodeUnlocked] = useState(false);

  // This runs when the page is loaded.
  useEffect(() => {
    if (localStorage.getItem(unlockCode)) {
      setCodeUnlocked(true);
    }
  }, []);

  const showAsLocked = (locked && !codeUnlocked) || false;

  return (
    <div className={`product-card ${showAsLocked ? 'locked' : ''}`}>
      <Link to={link}>
        <div className="product-img-container">
          <ImageStack
            main={thumb}
            over={finished}
            locked={showAsLocked}
            lockedLabel="🔒"
            reveal
          />
        </div>
      </Link>
      <p className="product-details">
        <strong>{title}</strong>
        <br />
        <span className="card-description">
          { difficulty && (
            <>
              Difficulty: {difficulty}
              <br />
            </>
          )}
          { description && `${description}` }
        </span>
        <br />
        { priceLabel && (
          <Link to={priceUrl}>
            <button type="button" className="button primary pill">
              {priceLabel}
            </button>
          </Link>
        )}
        { printFile && (
          <>
            { (!locked || codeUnlocked) && (
            <a href={printFile.publicURL} className="button primary pill">
            print
            </a>
            )}
            { (locked && !codeUnlocked) && (
            <button type="button" onClick={() => { ProductCard.unlockPrompt(title, unlockCode, link); }} className="button primary pill">
            unlock
            </button>
            )}
          </>
        )}
      </p>
    </div>
  );
};

ProductCard.unlockPrompt = (title, unlockCode, link) => {
  const answer = prompt('Enter keyphrase to unlock template:');
  if (answer && answer === unlockCode) {
    const redirectTo = `${window.location.protocol}//${window.location.host}/${link.replace(/^\/+/g, '')}`;
    // const msg = `SUCCESS! The ${title} template is now unlocked.\n\nClick "OK" to accept saving unlock progress as a cookie.\nClick "Cancel" to see template without saving.`;
    // if (window.confirm(msg)) {
    //   // Save unlock to local storage
    //   console.log('save unlock:', title, unlockCode);
    //   localStorage.setItem(unlockCode, 'true');
    // }
    const msg = `SUCCESS!\nThe ${title} template is now unlocked.`;
    window.alert(msg);
    // Save unlock to local storage
    localStorage.setItem(unlockCode, 'true');

    console.log(redirectTo);
    window.location.reload();
    // Redirect to newly unlocked template page
    // window.location.href = redirectTo;
  }
};

ProductCard.defaultProps = {
  locked: false,
  printFile: null,
  description: null,
  priceLabel: null,
  priceUrl: null,
  unlockCode: null,
};

ProductCard.propTypes = {
  title: PropTypes.string.isRequired,
  difficulty: PropTypes.string.isRequired,
  description: PropTypes.string,
  link: PropTypes.string.isRequired,
  locked: PropTypes.bool,
  unlockCode: PropTypes.string,
  priceLabel: PropTypes.string,
  priceUrl: PropTypes.string,
  thumb: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  finished: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  printFile: PropTypes.oneOfType([
    PropTypes.object,
  ]),
};

export default ProductCard;
