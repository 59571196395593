import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import '../utils/normalize.css';
import '../utils/css/screen.css';
import 'react-slideshow-image/dist/styles.css';

const ProcessSequence = (props) => {
  const {
    grid,
    inputSrc,
    inputMessage,
    outputSrc,
  } = props;

  // tempo
  const showLabels = false;

  return (
    <div className="process-example row">
      { grid && (
      <div className="col step">
        <div className="step-content arrow-right">
          { showLabels && (
          <React.Fragment>
            <span>
              Template:
              <br />
              <strong>
                {grid.frontmatter.title}
                {' '}
              </strong>
              {' '}
            </span>
            <br />
            <br />
          </React.Fragment>
          ) }
          <Link to={grid.fields.slug}>
            <Img fluid={grid.frontmatter.thumb.childImageSharp.fluid} />
          </Link>
          { showLabels && (
            <React.Fragment>
              <br />
              <a href={grid.frontmatter.printFile.publicURL} className="button primary pill">
                print
              </a>
              <br />
              <br />
            </React.Fragment>
          )}
        </div>
      </div>
      )}
      { inputSrc && (
      <div className="col step">
        {/* { showLabels && <span><strong>Input</strong></span> } */}
        <Img fluid={inputSrc.childImageSharp.fluid} className="step-content arrow-right" />
        {/* { showLabels && <span>Colored markers and pens.</span> } */}
      </div>
      )}
      { inputMessage && (
      <div className="col step">
        <div className="step-content arrow-right">
          <code>Settings</code>
          <div className="chat-container">
            <div className="yours messages">
              <div className="message last">
                {inputMessage}
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
      { outputSrc && (
      <div className="col step">
        <img src={outputSrc} className="step-content arrow-right" alt="output animation" />
      </div>
      )}
    </div>
  );
};

ProcessSequence.defaultProps = {
  grid: null,
  inputMessage: null,
  showLabels: false,
};

ProcessSequence.propTypes = {
  grid: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  inputSrc: PropTypes.oneOfType([
    PropTypes.object,
  ]).isRequired,
  inputMessage: PropTypes.string,
  outputSrc: PropTypes.string.isRequired,
  showLabels: PropTypes.boolean,
};

export default ProcessSequence;
