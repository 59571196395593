import React from "react"
import { Link } from "gatsby"

export default props => (
  <article
    className={`gallery-card`}
    style={
      props.node.frontmatter.anim && {
        backgroundImage: `url(${props.node.frontmatter.anim.publicURL})`,
      }
    }
  >
    <Link to={props.node.fields.slug} className="gallery-card-link">
      <div className="gallery-card-content">
        <h2 className="gallery-card-title">
          {props.node.frontmatter.title || props.node.fields.slug}
        </h2>
      </div>
    </Link>
  </article>
)
